import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import axios from "axios";

async function recuperer_langue(){
  let langue="";
  const data_langue = await axios.get("/recuperer_langue");
  langue=data_langue.data['langue'];
  return langue;
}
const langue= await recuperer_langue();
const resources = {
  fr: {
    translation: {
      //navbar:{
      accueil: "Accueil",
      quisommes: "Qui sommes-nous",
      services: "Services",
      actualites: "Actualités",
      nouveautes: "Nouveautés",
      communaute: "Communauté",
      shop: "Shop",
      sinscrire: "S'inscrire",
      login: "Se connecter",
      compte: "Mon compte",
      logout: "Se déconnecter",
      langue: "Français",

      //Mon compte :{
        Profil : "Profil",

      //Category
      Entreprises: "Entreprises",
      voir_plus: "voir plus",
      Saisissez_votre_recherche: "Saisissez votre recherche",
      Adresse_Code_postal: "Adresse/Code postal",
      Filtre_Niv: "Filtre: Niv.",
      Filtre_Nom: "Filtre: Nom.",
      Valider: "Valider",
      Effacer: "Effacer",
      Nom_entreprise: "Nom entreprise",
      Origine: "Origine",
      Date_répertoire_vert: "Date d'inscription au répertoire vert",
      Niveau: "Niveau"
      //}
    },

    // }
  },
  en: {
    translation: {
      // here we will place our translations...
      //navbar:{
      accueil: "Home",
      quisommes: "Who we are",
      services: "Services",
      actualites: "News",
      nouveautes: "New things",
      communaute: "Communities",
      shop: "Shop",
      sinscrire: "Register",
      compte: "My account",
      logout: "Log out",
      langue: "English",
      login: "Log in",

      //Mon compte :{
        Profil : "Profil",

      //Category
      Entreprises: "Company",
      // animaux: "Animals",
      // alimentation_boisson: "Food & Drinks",
      // Soin_beauté_du_corps: "Body care & Beauty",
      // bureautique: "Office automation",
      // tourisme_transport: "Tourism & Transportation",
      // consultant:"Consultant",
      // formation:"Training",
      // emploi_vert:"Green Job",
      // environnement:"Environment",
      // gestion_des_dechets:"Waste Management",
      // jardinage_et_plantes:"Gardening & Plants",
      // label:"Label",
      // loisirs_et_jeux:"Leisure & Games",     	
      // maison:"House",
      // media_et_communication:"Media & Communications",
      // mode_et_textile:"Fashion & Textiles",
      // ong_et_associations:"Ngos & Associations",
      // produits_menagers:"Household products",
      // Santé_et_bien_être:"Health & Wellbeing",
      // artisanat:"Arts & Crafts",
      // compensation_carbone:"Carbon Compensation",
      voir_plus: "see more",
      Saisissez_votre_recherche: "Enter your search",
      Adresse_Code_postal: "Address/Postcode",
      Filtre_Niv: "Filter: Lv.",
      Filtre_Nom: "Filter: Name.",
      Valider: "Validate",
      Effacer: "delete",
      Nom_entreprise: "Company name",
      Origine: "Origin",
      Date_répertoire_vert: "Date of registration in the green directory",
      Niveau: "Level",
      //}
      //Header
      header0: "Welcome to the",
      header1: "Green Index",
      header2: "The professional green index of sustainable development offers reference and evaluation",
      header3: "of all professional \"green\" services and products proposed within 130 km from Geneva ",
      //Production Responsible
      production_responsible0: "We create added value and stimulate eco-consumption",
      production_responsible1: "We provide consumers with a responsible tool choice and solutions for affordable prices.",
      production_responsible2: " \"Green\" is for us a synonym of eco-awareness, choice and sustainable, efficient and profitable behavior.",
      production_responsible3: "We also allow companies to be more visible, more involved, more efficient and more profitable.",
      production_responsible4: "SUSTAINABLE PRODUCTION",
      production_responsible5: "The",
      production_responsible6: "Green Index",
      production_responsible7: "allows you to increase",
      production_responsible8: "visibility",
      production_responsible9: "and to have better",
      production_responsible10: "distribution channels",
      production_responsible11: "on",
      production_responsible12: "green products",
      production_responsible13: "and",
      production_responsible14: "services",
      production_responsible15: ", with the aim of",
      production_responsible16: "increasing",
      production_responsible17: "the",
      production_responsible18: "sales",
      production_responsible19: "of your green products and services.",
      production_responsible20: "Thanks to",
      production_responsible21: "the audit process",
      production_responsible22: ", you will not only be able",
      production_responsible23: "to reduce",
      production_responsible24: "your",
      production_responsible25: " environmental impact,",
      production_responsible26: "but also",
      production_responsible27: "to strengthen",
      production_responsible28: "the ",
      production_responsible29: "trust capital",
      production_responsible30: "of your company with eco-consumers.",
      //Secteurs d'activités
      secteurs_activites_titre: "Business Sectors",
      secteurs_activites_contenu: "The Green Index is divided into 27 business sectors, ranging from food, construction, textiles, media and many more.",
      // ModeleConcept
      modele0:"Model and theory",
      modele1:"The Green Index is based on",
      modele2:"1 QUALITATIVE AND QUANTITATIVE QUESTIONNAIRE",
      modele3:"developed with the participation of researchers and experts in the following fields:",
      modele4:"Lean management & organization",
      modele5:"Product life cycle",
      modele6:"Environmental management system",
      modele7:"Quality of life at work",
      modele8:"Mobility and Transport",
      modele9:"Enterprise's social responsibility",
      modele10:"Impact studies",
      modele11:"Project management",
      modele12:"Sustainable management",
      //Evaluation System
      evaluation_system0: "Evaluation System",
      evaluation_system1: "It determines 4 levels of referencing based on a triple evaluation:",
      evaluation_system2: "Level 0 : External actor",
      evaluation_system3: "Level 1:  Member of gaea21 association ",
      evaluation_system4: "Level 2: Network member gaea21 business",
      evaluation_system5: "Level 3: Holder of  gaea21 label",
      evaluation_system6: "Evaluation criteria:",
      evaluation_system7: " - from gaea21 organization",
      evaluation_system8: "- from professionals of the concerned sector",
      evaluation_system9: "- from the public (les consom’acteurs)",
      // Associated Programs
      AssociatedPrograms: "Associated programs",
      page_404_0:"Welcome to the",
      page_404_1:"Green Index",
      page_404_2:"Page under construction",
      page_404_3:"For every information request : ",
      main_partners: "Main Partners"
    },
  },
  de: {
    translation: {
      // here we will place our translations...
      //navbar:{
      accueil: "Willkommen",
      quisommes: "Wer sind wir",
      services: "Dienstleistungen",
      actualites: "Zeitgeschehen",
      nouveautes: "Neuheiten",
      communaute: "Gemeinschaft",
      shop: "Shop",
      sinscrire: "Registrierung",
      login: "Anmeldung",
      //}
    },
  },
  it: {
    translation: {
      // here we will place our translations...
      //navbar:{
      accueil: "Benvenuti",
      quisommes: "Chi siamo",
      services: "Servizi",
      actualites: "News",
      nouveautes: "Novità",
      communaute: "Comunità",
      shop: "Shop",
      sinscrire: "Iscrizione",
      login: "Log in",
      //}
    },
  },
  es: {
    translation: {
      // here we will place our translations...
      //navbar:{
      accueil: "Página de inicio",
      quisommes: "¿Quién somos?",
      services: "Servicios",
      actualites: "Actualidades",
      nouveautes: "Novedades",
      communaute: "Comunidad",
      shop: "Shop",
      sinscrire: "Inscripción",
      login: "Conexión",
      //}
    },
  },
};
i18n
  // pass the i18n instance to react-i18next.0
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
      lng: langue,
      resources: resources,
      debug: true,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      }
    }
  );
export default i18n;
